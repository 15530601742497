'use client';

import { Spinner } from 'flowbite-react';
import * as React from 'react';

export default function Loading() {
    return (
        <div className="mx-auto mt-28 text-center">
            <Spinner />
        </div>
    );
}
